<template>
  <div>
    <v-toolbar color="transparent" dense flat>
      <v-toolbar-title>{{ $t('widgets.tasks.name') }} ({{ (tasks||[]).length }} / {{ (tasksDatas.tasks||[]).length}})</v-toolbar-title>

      <add-task @add="add"></add-task>


      <v-btn icon small class="ml-4" @click="toggleShowCompletedTasks"
             :title="$t('widgets.tasks.actions.' + (showCompletedTasks ? 'hideCompletedTasks' : 'showCompletedTasks'))">
        <v-icon>{{ showCompletedTasks ? 'mdi-eye-off' : 'mdi-eye' }}</v-icon>
      </v-btn>

<!--      <v-btn icon small class="ml-4" @click="removeCompletedItems">-->
<!--        <v-icon>mdi-playlist-remove</v-icon>-->
<!--      </v-btn>-->


    </v-toolbar>

    <v-card-text class="pt-2 overflow-y-auto" v-if="!moving">

      <v-list dense>
        <v-list-item dense>
          <v-list-item-icon>{{ progressionHumanReadable }}</v-list-item-icon>
          <v-list-item-title>
            <v-progress-linear :color="progression == 100 ? 'success' : 'primary'" :value="progression"></v-progress-linear>
          </v-list-item-title>
        </v-list-item>
      </v-list>

      <template v-if="(tasks||[]).length > 0">
        <v-list dense class="pt-0 list-tasks">
          <!--          <v-hover v-slot="{hover}" >-->
          <edit-task v-for="task of tasks"
                     :key="task.id"
                     :value="task"
                     :progression="progression"
                     @update="update"
                     @remove="remove"
          >
          </edit-task>
          <!--          </v-hover>-->
        </v-list>
      </template>
      <template v-else>
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="grey--text">
                {{ $t('widgets.tasks.noTasks') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>

    </v-card-text>
  </div>
</template>

<script>
import Vuex from "vuex";
import PlaneteOnlineService from "@/Services/PlaneteOnlineService";

export default {
  name: "WidgetTasks",
  props: {
    moving: Boolean
  },
  components: {
    AddTask: () => import("@/Components/Views/Home/Widgets/WidgetTasks/AddTask"),
    EditTask: () => import("@/Components/Views/Home/Widgets/WidgetTasks/EditTask")
  },
  computed: {
    ...Vuex.mapState(['widgetBoard']),
    tasksDatas() {
      return this.$store.getters.getWidgetDatas("tasks")
    },
    tasks() {
      return this.tasksDatas.tasks.filter(a => this.showCompletedTasks ? true : !a.done)
    },
    tasksPreferences() {
      return this.tasksDatas.preferences
    },
    showCompletedTasks: {
      get() {
        return this.tasksDatas.preferences.showCompletedTasks
      },
      set(val) {
        this.$store.commit("showCompletedTasks", {state: val})
      }

    },
    progression() {
      return ((this.tasksDatas.tasks.filter(a => a.done).length / this.tasksDatas.tasks.length) || 0) * 100
    },
    progressionHumanReadable() {
      return Math.round(this.progression) + "%"
    }
  },
  data() {
    return {}
  },
  methods: {
    async toggleShowCompletedTasks() {
      this.showCompletedTasks = !this.showCompletedTasks
      let store = this.$_.pick(this.$store.state, ["widgetBoard"])
      await PlaneteOnlineService.postInterface(store)
    },
    removeCompletedItems: function () {

    },
    startDrag: function (e) {
    },
    update: function (data) {
      this.$emit("update", {
        widgetName: "tasks",
        dimension: "tasks",
        data: data
      })
      this.selectedOpen = false
    },
    add: function (data) {
      this.$emit("add", {
        widgetName: "tasks",
        dimension: "tasks",
        data: data
      })
    },
    remove: function (data) {
      this.$emit("remove", {
        widgetName: "tasks",
        dimension: "tasks",
        data: data
      })
    },
  },
}
</script>

<style>
   .list-tasks .v-list-item__subtitle {
      white-space: normal !important;
      text-overflow: initial !important;
  }
</style>
